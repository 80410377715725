import axios from "axios";
export const BASEURL = 'https://core-prod-origin.cricclubs.com'

export const getByUser = async (user) => {
    const headers = {
        'x-consumer-key': 'raVI88cc',
        'x-api-key' : 'KmBjP2024',
        'Content-Type': 'application/json',
      };
    try {
        return axios.post(`${BASEURL}/core/contact/ninjacaster`, user,{headers: headers});

        } catch (err) {
            throw new Error(err.message); 
          }

}